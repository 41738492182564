import * as React from "react"
import { useState, useEffect } from "react"

import FitnessServices from "../components/fitness-services"
import Contact from "../components/contact"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../styles/fitneszszolgaltatasok.module.scss"
import parallaxImage from "../images/fitnessgym.jpg"

const IndexPage = () => {
  const [top, setTop] = useState(0)
  let speed = 3
  useEffect(() => {
    const handleScroll = () => {
      // Az  "500"-as érték a csúszó képet tartalmazó div magassága, ami a benne lévő kép pozíciójának a számításához szükséges. A "speed" gyorsítható a kép csúszása a scrollozáshoz képest. "200"-as érték csak egy igazítás a kép pozíciünálásához.
      let newTop =
        (speed * (500 * window.pageYOffset)) / document.body.scrollHeight
      setTop(newTop)
    }
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  })
  useEffect(() => {
    setTop((speed * (500 * window.pageYOffset)) / document.body.scrollHeight)
  }, [speed])

  return (
    <Layout>
      <Seo title="Szolgáltatások" />
      <div id={styles.container}>
        <section id={styles.bar1}>
          <div id={styles.header}>
            <h2>Szolgáltatásaim</h2>
            <p>
              Ne habozz sokáig, ha fontos az egészséged...gyere és sportolj
              velem!
            </p>
          </div>
        </section>

        <FitnessServices />

        <section id={styles.bar3}>
          <div className={styles.container}>
            <section className={styles.picture}>
              {/* ABLAKBAN CSÚSZKÁLÓ KÉP */}
              {/* Ezt a feltételt azért tettem be, hogy oldalfrissítéskor is érvényesüljön a kép pozíciója. Ha nincs benne, akkor az alapbeállítás miatt a kép elcsúszik */}
              {/* A képet úgy kell beállítani, hogy a magassága jóval nagyobb legyen a div magasságánál.*/}
              {/* A "top-speed*200" és left értékekkel szabályozható, hogy a kép mely része legyen látható az ablakban*/}
              {top !== 0 ? (
                <img
                  src={parallaxImage}
                  alt="Created by schantalao - www.freepik.com"
                  style={{
                    position: "absolute",
                    transform: `translate(0px,${top - speed * 200}px)`,
                    height: "250%",
                    bottom: "-100px",
                  }}
                />
              ) : (
                <div></div>
              )}
            </section>
          </div>
        </section>

        <Contact />
      </div>
    </Layout>
  )
}

export default IndexPage
